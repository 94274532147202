<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="小区 " prop="villageId" >

        <a-select
          v-model="form.villageId"
          showSearch
          style="width: 100%"
          placeholder="请选择"
          allow-clear
          optionFilterProp="children"
        >
          <a-select-option :value="item.id" :key="item.id" :title="item.title" v-for="(item,index) in listAll">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="价格" prop="price" >
        <a-input-number style='width: 80%' :min='0.01' :max='9999999' v-model="form.price" placeholder="请输入价格" />
      </a-form-model-item>
      <a-form-model-item label="置顶时长" prop="topTime" >
        <a-input-number style='width: 80%' :min='1' :max='9999999' v-model="form.topTime" placeholder="请输入置顶时长（整数）" />
<!--        <a-input v-model="form.topTime" placeholder="请输入置顶时长" />-->
      </a-form-model-item>
      <a-form-model-item label="置顶单位 " prop="topType" >
        <a-select placeholder="请选择"  v-model="form.topType">
          <a-select-option :key="0">小时</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="物业分佣百分比" prop="percentage" >
        <a-input-number style='width: 80%' :min='1' :max='100' v-model="form.percentage" placeholder="请输入物业分佣百分比（整数）" />
<!--        <a-input v-model="form.percentage" placeholder="请输入物业分佣百分比" />-->
      </a-form-model-item>
<!--      <a-form-model-item label="ios内购产品id" prop="productId" >-->
<!--        <a-input v-model="form.productId" placeholder="请输入ios内购产品id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTopConfig, addTopConfig, updateTopConfig } from '@/api/user/topConfig'
import {listVillage} from '@/api/village/village'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      listAll: [],
      // 表单参数
      form: {
        id: null,

        villageId: null,

        price: null,

        topTime: null,

        topType: null,

        percentage: null,

        productId: null,

        isOpen: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {

        villageId: [
          { required: true, message: '小区不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '价格不能为空', trigger: 'blur' }
        ],
        topTime: [
          { required: true, message: '置顶时长不能为空', trigger: 'blur' }
        ],
        topType: [
          { required: true, message: '置顶单位0小时1天不能为空', trigger: 'change' }
        ],
        percentage: [
          { required: true, message: '物业分佣百分比不能为空', trigger: 'blur' }
        ],
        isOpen: [
          { required: true, message: '是否启用不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getVillageList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getVillageList(){
      listVillage({}).then(res => {
        this.listAll = res.data;
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        villageId: null,
        price: null,
        topTime: null,
        topType: null,
        percentage: null,
        productId: null,
        isOpen: null,
        createTime: null,
        remark: null
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTopConfig({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTopConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTopConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
