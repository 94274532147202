import request from '@/utils/request'


// 查询小区置顶配置列表
export function listTopConfig(query) {
  return request({
    url: '/user/village-top-configs/list',
    method: 'get',
    params: query
  })
}

// 查询小区置顶配置分页
export function pageTopConfig(query) {
  return request({
    url: '/user/village-top-configs/page',
    method: 'get',
    params: query
  })
}

// 查询小区置顶配置详细
export function getTopConfig(data) {
  return request({
    url: '/user/village-top-configs/detail',
    method: 'get',
    params: data
  })
}

// 新增小区置顶配置
export function addTopConfig(data) {
  return request({
    url: '/user/village-top-configs/add',
    method: 'post',
    data: data
  })
}

// 修改小区置顶配置
export function updateTopConfig(data) {
  return request({
    url: '/user/village-top-configs/edit',
    method: 'post',
    data: data
  })
}

// 删除小区置顶配置
export function delTopConfig(data) {
  return request({
    url: '/user/village-top-configs/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/user/village-top-configs/edit',
    method: 'post',
    data: data
  })
}